<template>
  <vx-card no-shadow>

    <!-- Img Row -->
    <div class="flex flex-wrap items-center mb-base">
      <vs-avatar :src="activeUserInfo.photoURL" class="mr-4 mb-4" size="70px"/>
      <div>
        <input @change="selectFile" class="d-none pb-2 mt-3" id="file" ref="file" type="file"><br/>
        <vs-button @click="submitPicture" class="mr-4 sm:mb-0 mb-2">Upload photo</vs-button>
        <vs-button @click="removePicture" color="danger" type="border">Remove</vs-button>
        <p class="text-sm mt-2">Allowed JPG or PNG. Max size of 2MB</p>
      </div>
    </div>

    <!-- Info -->

    <div class="vx-row">
      <vs-input class="vx-col w-1/2 mb-base" label-placeholder="First Name" v-model="first_name"></vs-input>
      <vs-input class="vx-col w-1/2 mb-base" label-placeholder="Last Name" v-model="last_name"></vs-input>
    </div>
    <vs-input class="vx-col w-full" label-placeholder="Email" v-model="email"></vs-input>

    <vs-input class="vx-col w-full my-base" label-placeholder="Company" v-model="company"></vs-input>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button @click="initiateUserUpdate" class="ml-auto mt-2">Save Changes</vs-button>
      <vs-button @click="resetFields" class="ml-4 mt-2" color="warning" type="border">Reset</vs-button>
    </div>
  </vx-card>
</template>

<script>

export default {
  data () {
    return {
      first_name: '',
      last_name: '',
      email: '',
      company: '',
      file: ''
    }
  },
  created () {
    this.resetFields()
  },
  methods: {
    initiateUserUpdate () {

      const payload = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        company: this.company
      }

      this.$vs.loading()

      this.$http.post('users/@me', payload)
        .then(response => {
          this.$vs.loading.close()

          if (response.data && response.data.data) {

            this.$gtag.event('usr_sts_general', {
              event_category: 'user_settings',
              event_label: 'usr_sts_general',
            });

            this.$store.dispatch('auth/refreshUserObject')

            return this.$vs.notify({
              title: 'Success',
              text: 'Your settings have been updated',
              iconPack: 'feather',
              icon: 'icon-user-check',
              color: 'success'
            })
          }

        })
        .catch(exception => {
          this.$vs.loading.close()


          if (exception.response) {
            const response = exception.response
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            iconPack: 'feather',
            icon: 'icon-user-x',
            color: 'danger'
          })
        })
    },
    removePicture () {

      this.$vs.loading()

      this.$http.delete('users/@me/picture')
        .then((response) => {
          this.$vs.loading.close()

          if (response.response) {
            response = response.response
          }

          if (response.data) {
            this.$store.dispatch('auth/refreshUserObject')

            this.$vs.notify({
              title: 'Success',
              text: 'Your settings have been updated',
              iconPack: 'feather',
              icon: 'icon-user-check',
              color: 'success'
            })
          }

        })
        .catch(e => {
          this.$vs.loading.close()
        })
    },
    submitPicture () {

      if (typeof this.file !== 'object') {
        return this.$vs.notify({
          title: 'Error',
          text: 'You must select a file to upload',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
      this.$vs.loading()

      const formData = new FormData()
      formData.append('upload', this.file)

      this.$http.post('users/@me/picture', formData)
        .then((response) => {
          this.$vs.loading.close()

          if (response.response) {
            response = response.response
          }

          if (response.data) {
            this.$store.dispatch('auth/refreshUserObject')

            this.$vs.notify({
              title: 'Success',
              text: 'Your settings have been updated',
              iconPack: 'feather',
              icon: 'icon-user-check',
              color: 'success'
            })
          }

        })
        .catch(e => {
          this.$vs.loading.close()
        })

    },
    selectFile () {
      const files = this.$refs.file.files

      if (files.length > 0) {
        this.file = files[0]
      }

    },
    resetFields () {
      this.first_name = this.$store.state.AppActiveUser.first_name
      this.last_name = this.$store.state.AppActiveUser.last_name
      this.email = this.$store.state.AppActiveUser.email
      this.company = this.$store.state.AppActiveUser.company || ''
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  }
}
</script>
