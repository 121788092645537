<template>
  <vx-card no-shadow>

    <!-- DOB -->
    <div class="mt-8">
      <label class="text-sm">Birth Date</label>
      <flat-pickr :config="{ dateFormat: 'd F Y' }" class="w-full" v-model="dob"/>
    </div>

    <!-- Languages -->
    <div class="mt-8">
      <label class="text-sm">Language</label>
      <v-select :closeOnSelect="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" :options="langOptions" v-model="lang_known"/>
    </div>

    <!-- Mobile Number -->
    <vs-input class="w-full mt-8" label-placeholder="Mobile" type="mobile" v-model="mobile"/>

    <!-- Website  -->
    <vs-input class="w-full mt-8" label-placeholder="Website" v-model="website"/>

    <!-- Gender -->
    <div class="mt-8 mb-base">
      <label class="text-sm">Gender</label>
      <div class="mt-2">
        <vs-radio class="mr-4" v-model="gender" vs-value="male" @input="custom_gender = false">Male</vs-radio>
        <vs-radio class="mr-4" v-model="gender" vs-value="female" @input="custom_gender = false">Female</vs-radio>
        <vs-radio v-model="gender" @input="custom_gender = true">Other</vs-radio>

        <vs-input v-if="custom_gender"  class="w-full mt-4" data-vv-as="field" label="Gender"
                   name="gender" v-model="gender"/>

      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button @click="initiateUserUpdate" class="ml-auto mt-2">Save Changes</vs-button>
      <vs-button class="ml-4 mt-2" color="warning" type="border">Reset</vs-button>
    </div>
  </vx-card>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'

export default {
  components: {
    flatPickr,
    vSelect
  },
  methods: {
    initiateUserUpdate () {

      if(this.gender !== 'male' && this.gender !== 'female') {
        return this.$vs.notify({
          title: 'Failed to set gender',
          text: 'Please choose a real gender.',
          color: 'danger',
          position: 'top-right',
        });
      }

      const payload = {
        dob: this.dob,
        country: this.country,
        language: this.lang_known.value || 'english',
        gender: this.gender,
        phone_number: this.mobile,
        website: this.website
      }

      this.$vs.loading()

      this.$http.post('users/@me', payload)
        .then(response => {
          this.$vs.loading.close()

          if (response.data && response.data.data) {

            this.$gtag.event('usr_sts_personal', {
              event_category: 'user_settings',
              event_label: 'usr_sts_personal',
            });

            this.$store.dispatch('auth/refreshUserObject')

            return this.$vs.notify({
              title: 'Success',
              text: 'Your settings have been updated',
              iconPack: 'feather',
              icon: 'icon-user-check',
              color: 'success'
            })
          }

        })
        .catch(exception => {
          this.$vs.loading.close()

          if (exception.response) {
            const response = exception.response
          }

          return this.$vs.notify({
            title: 'Failed to update',
            text: (response || {message: 'An unknown error has occurred'}).message,
            iconPack: 'feather',
            icon: 'icon-user-x',
            color: 'danger'
          })
        })
    },

    resetFields () {
      this.dob = this.$store.state.AppActiveUser.dob || ''
      this.country = this.$store.state.AppActiveUser.country || 'USA'
      this.lang_known = this.$store.state.AppActiveUser.language || 'English'
      this.gender = (this.$store.state.AppActiveUser.gender || 'male').toLowerCase()
      this.mobile = this.$store.state.AppActiveUser.phone_number || ''
      this.website = this.$store.state.AppActiveUser.website || ''
    }
  },
  created () {
    this.resetFields()
  },
  data () {
    return {

      dob: '',
      country: 'USA',
      lang_known: 'English',
      gender: 'male',
      mobile: '',
      website: '',

      custom_gender: false,

      // Options
      countryOptions: [
        {label: 'Australia', value: 'australia'},
        {label: 'France', value: 'france'},
        {label: 'Germany', value: 'germany'},
        {label: 'India', value: 'india'},
        {label: 'Jordan', value: 'jordan'},
        {label: 'Morocco', value: 'morocco'},
        {label: 'Portuguese', value: 'portuguese'},
        {label: 'Syria', value: 'syria'},
        {label: 'USA', value: 'usa'}
      ],
      langOptions: [
        {label: 'English', value: 'english'},
        {label: 'Spanish', value: 'spanish'},
        {label: 'French', value: 'french'},
        {label: 'Russian', value: 'russian'},
        {label: 'German', value: 'german'},
        {label: 'Arabic', value: 'arabic'},
        {label: 'Sanskrit', value: 'sanskrit'}
      ]
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  }
}
</script>
