<template>
  <vx-card class="mb-base" title="Payment Options">

      <div class="mt-3">
        <ul>
          <li :key="method.id" v-for="(method, index) in this.methods">
            <div class="flex flex-wrap justify-between items-center">
              <div class="flex items-center">
                    <span>
                      {{ method.card.country | capitalize }}
                      {{ method.card.brand | title }}
                      {{ method.card.funding | title }}
                      Card &mdash;X-{{ method.card.last4 }}
                    </span>
                &nbsp;&nbsp;&nbsp;
                <span>{{ method.card.exp_month }}/{{ method.card.exp_year }}</span>
              </div>
              <span><vs-button @click="removeAttachments" color="danger" size="small">&minus; Remove</vs-button></span>
            </div>
            <vs-divider class="my-6"/>
          </li>

          <li v-if="this.methods.length === 0">
            <vs-col vs-w="6" vs-offset="3">

              <vs-input class="w-full mb-3" label-placeholder="Card Number" v-model="number" />
              <vs-row vs-justify="space-between">
                <vs-input max="12" min="1" type="number" class="w-w/3" label-placeholder="Card Expire Month" v-model="month" />
                <vs-input max="45" min="20" type="number" class="w-w/3" label-placeholder="Card Expire Year" v-model="year" />
                <vs-input min="001" max="999" type="number" class="w-w/3" label-placeholder="Card CVC" v-model="cvc" />
              </vs-row>

            </vs-col>

            <vs-divider class="pt-5"/>
            <div class="flex flex-wrap items-center justify-end mt-base">
              <vs-button @click="submitAttachment" class="ml-auto ">Create Method</vs-button>
            </div>
          </li>


        </ul>


      </div>

  </vx-card>
</template>

<script>
export default {
  data() {
    return {
      methods: [],
      number: '',
      month: '',
      year: '',
      cvc: ''
    };
  },
  mounted() {
    this.loadAttachedMethods();
  },
  methods: {
    loadAttachedMethods() {

      this.$http.get(`users/@me/customer/methods`)
        .then(response => {

          if (response.status === 200) {
            response.data.data.forEach(method => {
              this.methods.push(method);
            });
          }

        });

    },
    submitAttachment() {
      this.$vs.loading();
      this.$http.post(`billing/@me/methods`, {
        number: this.number,
        month: this.month,
        year: this.year,
        cvc: this.cvc,
      })
      .then(response => {
        this.$vs.loading.close();

        if (response.status === 200) {

          this.$gtag.event('usr_sts_billing_pm_add', {
            event_category: 'user_settings',
            event_label: 'usr_sts_billing_pm_add',
          });

          if (this.$store.state.eCommerce.cartItems.length > 0) {
            this.$router.push('/checkout');
          }

          this.methods = response.data.data;

          return this.$vs.notify({
            color: 'success',
            title: 'Added Payment Method',
            text: 'Your payment card has been added to your account.',
          });
        }

      })
      .catch(exception => {
        this.$vs.loading.close();

        let error = 'An unknown error occurred while creating this method';
        if (exception.response) {
          if (exception.response.data.data) {
            error = exception.response.data.data.message;
          } else if (exception.response.data.error) {
            error = exception.response.data.error.description;
          }
        }

        return this.$vs.notify({
          title: 'Failed to submit method',
          text: error,
          color: 'danger',
          position: 'top-right',
        });
      })
    },
    removeAttachments() {
      return this.$vs.notify({
        color: 'danger',
        title: 'Failed to Remove Method',
        text: 'Please contact our support team to have payment methods removed.',
      });
    },
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
};
</script>
