<template>
  <vx-card no-shadow>

    <vs-input type="password" class="w-full mb-base" label-placeholder="Current Password" v-model="old_password"/>
    <vs-input type="password" class="w-full mb-base" label-placeholder="New Password" v-model="new_password"/>
    <vs-input type="password" class="w-full mb-base" label-placeholder="Confirm Password" v-model="confirm_new_password"/>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button @click="initiatePasswordReset" class="ml-auto mt-2">Save Changes</vs-button>
      <vs-button class="ml-4 mt-2" color="warning" type="border">Reset</vs-button>
    </div>
  </vx-card>
</template>

<script>
export default {
  data () {
    return {
      old_password: '',
      new_password: '',
      confirm_new_password: ''
    }
  },
  methods: {
    initiatePasswordReset () {

      if (this.confirm_new_password !== this.new_password) {
        return this.$vs.notify({
          title: 'Invalid Password',
          text: 'Your new password and your new password confirmation do not match',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }

      // Loading
      this.$vs.loading()

      const payload = {
        password: this.new_password,
        password_confirm: this.old_password
      }

      this.$http.post('users/@me', payload)
        .then(response => {
          this.$vs.loading.close()

          if (response.status === 200) {
            this.$gtag.event('usr_sts_auth_cp', {
              event_category: 'user_settings',
              event_label: 'usr_sts_auth_cp',
            });

            return this.$vs.notify({
              title: 'Success',
              text: 'You have updated your password information',
              iconPack: 'feather',
              icon: 'icon-user-check',
              color: 'success'
            })
          }


        })
        .catch(exception => {
          this.$vs.loading.close()

          if (exception.response) {

            const response = exception.response


            if (response.status === 403) {
              return this.$vs.notify({
                title: 'Failed to update',
                text: 'The current password you set does not match your existing password',
                iconPack: 'feather',
                icon: 'icon-user-x',
                color: 'danger'
              })
            } else if (response.status === 400) {
              return this.$vs.notify({
                title: 'Failed to update',
                text: response.data.error.description,
                iconPack: 'feather',
                icon: 'icon-user-x',
                color: 'danger'
              })
            }
          }


        })
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  }
}
</script>
